export const SHOW_TOAST = 'SHOW_TOAST';
// export const showToast = (toast) => ({
//     type: SHOW_TOAST, payload: toast
// });

export const SHOW_TOAST_OVERRIDE = 'SHOW_TOAST_OVERRIDE';
// export const showToastOvveride = (toast) => ({
//     type: SHOW_TOAST_OVVERIDE, payload: toast
// });

export const HIDE_TOAST = 'HIDE_TOAST';
export const hideToast = () => ({
    type: HIDE_TOAST
});
