Date.prototype.isValid = function () {
    return this.getTime() === this.getTime();
}

Date.prototype.readable = function (s = '-') {
    return (this.getDate() + s + ((this.getMonth()+1)+'').padStart(2, '0') + s + this.getFullYear() + '');
}

Date.prototype.readableUS = function (s = '-') {
    return ( this.getFullYear() + s + ((this.getMonth()+1)+'').padStart(2, '0') + s + this.getDate() + '');
}