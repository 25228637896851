export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const fetchUsers = () => ({
  type: FETCH_USERS
});

export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILED = 'CHANGE_STATUS_FAILED';
export const changeStatus = (params) => ({
  type: CHANGE_STATUS, payload: params
});
