
import * as actions from './actions';

const initialState = {
    current_toast: null,
    open: true
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SHOW_TOAST:
            return {
                ...state,
                current_toast: action.payload,
                open: true
            };
            break;
        case actions.HIDE_TOAST:
            return {
                ...state,
                open: false,
            }
        default:
            return state;
            break;
    }
}