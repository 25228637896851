import React from 'react';
import { connect } from 'react-redux';
import { fetchUsers, changeStatus } from './actions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SnapChatLoader from '../LoadingComponent/SnapChatLoader';
import ProtectedComponent from '../Auth/ProtectedComponent';

const classes = {
  containerWithMarginTop: {
    marginTop: '56px',
  },
  title: {
    fontSize: '1.7rem',
    padding: '.5rem 0',
    fontWeight: '100',
    color: 'rgba(0,0,0,.3)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  table: {
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0 3px 7px 0 rgba(0,0,0,.3)',
  }
};

const createTHeadStyle = (societe) => ({
  backgroundColor: societe.background_color,
  color: 'white',
});

class Users extends React.Component {

  componentDidMount() {
    this.props.fetchUsers();
  }

  showRole(role) {
    switch (role) {
      case 0:  
        return 'Super-utilisateur';
        break;
      case 1:
        return 'Administrateur';
        break;
      case 2:
        return 'Superviseur';
        break;
      case 3:
        return 'VRP';
        break;
      default:
        return 'Rôle inconnu';
        break;
    }
  }

  render() {
    const { fetching, users } = this.props.users;

    //
    if (fetching || !users) return <LoadingComponent />

    return (
      <div style={classes.containerWithMarginTop}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">

              {/* Inside the wrapper container */}
              <div style={classes.title}>
                Utilisateurs
              </div>
              <table style={classes.table} className='table table-responsive-md table-striped'>
                <thead style={createTHeadStyle(this.props.societe)} >
                  <tr>
                    <th>
                      Prénom
                    </th>
                    <th>
                      Nom
                    </th>
                    <th>
                      Login
                    </th>
                    <th>
                      Statut
                    </th>
                    <th className='text-truncate'>
                      Code VRP
                    </th>
                    <ProtectedComponent role={1}>
                      <th>
                        Actif
                      </th>
                    </ProtectedComponent>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((user, key) => (
                      <tr>
                        <td>{user.firstname}</td>
                        <td>{user.lastname}</td>
                        <td>{user.username}</td>
                        <td>{this.showRole(user.role)}</td>
                        <td>{user.codvrp || ''}</td>
                        <ProtectedComponent role={1}>
                          <td>
                            <input type='checkbox' disabled={this.props.changingStatus || this.props.user.id === user.id} defaultChecked={user.status} onChange={(e) => this.props.changeStatus({
                              status: e.target.checked ? 1 : 0,
                              id_user: user.id,
                            })}/>
                          </td>
                        </ProtectedComponent>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  societe: state.societes.current_societe,
  changingStatus: state.users.changingStatus,
  user: state.auth.user,
});

export default connect(mapStateToProps, { fetchUsers, changeStatus })(Users);
