import * as actions from './actions';

const initialState = {
    selected_date: (new Date).readableUS(),
};

export default function (state=initialState, action) {
    switch (action.type) {
        case actions.CHANGE_DATE: 
            return {
                ...state,
                selected_date: action.payload,
            };
            break;
        default: 
            return state;
            break;
    }
};
