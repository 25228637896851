import React from 'react';
import { connect } from 'react-redux';
import { hideToast } from './actions';

const getBackgroundColor = (type) => {
    switch (type) {
        case 'error':
            return 'FireBrick';
        case 'warning': 
            return 'DarkOrange';
        default: 
            return 'ForestGreen';
    }
}

const createToastContainerStyle = open => ({
    position: 'fixed',
    transition: 'all .225s ease',
    bottom: 0,
    width: '100%',
    zIndex: 1000,
    display: 'flex',
    padding: '1rem',
    transform: 'translateY('+(open ? '0' : '100%')+')',
});

const createToastStyle = toast => ({
    transition: 'all .5s ease',
    color: 'white',
    backgroundColor: getBackgroundColor(toast.type),
    padding: '1rem',
    borderRadius: '5px',
    textTransform: 'uppercase',
});

class Toast extends React.Component {
    state = {};

    handleAutoHiding() {
        const { open, hideToast } = this.props;
        const { delay } = this.props.current_toast;
        
        if (open && delay && !this.state.timer) {
            let timer = window.setTimeout(() => {
                hideToast();
                this.setState({ timer: null });
            }, delay);

            this.setState({ timer });
        }

        if (this.state.timer && !delay) {
            this.dismissTimer();
        }
    }

    dismissTimer() {
        window.clearTimeout(this.state.timer);
        this.setState({ timer: null });
    }
    //
    componentWillUnmount() {
        if (this.state.timer) {
            this.dismissTimer();
        }
    }
    render() {
        const { current_toast, open } = this.props;

        if (!current_toast) return null;
        
        const {
            type,
            message,
            delay,
            component,
            render,
        } = current_toast;
        
        console.log(component, message);
        this.handleAutoHiding();

        return (
                <div style={createToastContainerStyle(open)}>
                    <div style={createToastStyle(current_toast)}>
                        { message && message }
                        {/* { component && component } */}
                        { render ? render() : null }
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.toasts.open,
    current_toast: state.toasts.current_toast,
});

export default connect(mapStateToProps, { hideToast })(Toast);