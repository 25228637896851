import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILED,
} from './actions';

const initialState = {
  users: [],
  fetching: false,
  changingStatus: false,
};

export default function (state=initialState, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        fetching: true,
      };
      break;
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        users: action.payload,
      };
      break;
    case FETCH_USERS_FAILED:
      return {
        ...state,
        fetching: false,
        users: [],
      };
      break;
    case 'CHANGE_STATUS':
      return {
        ...state,
        changingStatus: true,
      };
      break;
    case 'CHANGE_STATUS_SUCCESS': {
      let index = state.users.findIndex(user => user.id === action.payload.id);
      let users = state.users;
      if (index >= 0) {
        users[index] = action.payload;
      }
      return {
        ...state,
        users,
        changingStatus: false,
      };
    }
    break;
    case 'CHANGE_STATUS_FAILED':
      return {
        ...state,
        changingStatus: false,
      };
      break;
    default:
      return state;
  }
}
