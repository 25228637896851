import React from 'react';
import Appbar from '../components/Headers/Appbar';
import Parameters from '../components/Societe/Parameters';

class ParametersPage extends React.Component {
  render() {
    return (
      <>
        <Appbar />
        <Parameters />
      </>
    );
  }
}

export default ParametersPage;
