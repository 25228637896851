import { call, put, takeLatest } from 'redux-saga/effects';
import { users } from './api';

export function* fetchUsers (action) {
  try {
    const all = yield call(users.fetchUsers);
    yield put({type: 'FETCH_USERS_SUCCESS', payload: all});
  } catch (e) {
    yield put({type: 'FETCH_USERS_FAILED', payload:e.response.data});
  }
}

export function* changeStatus (action) {
  try {
    const response = yield call(users.changeStatus, action.payload);
    yield put({type: 'CHANGE_STATUS_SUCCESS', payload: response});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Modifications enregistrées.',
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'CHANGE_STATUS_FAILED', payload:e.response.data});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Oups.. Vérifier votre connexion internet ou tentez de vous reconnecter.',
        type: 'error',
        float: true,
      }
    });
  }
}

function* usersSaga() {
  yield takeLatest('FETCH_USERS', fetchUsers);
  yield takeLatest('CHANGE_STATUS', changeStatus);
}

export default usersSaga;
