import React from 'react'
import { connect } from 'react-redux';
import { changeDate } from './actions';
import { fetchReporting } from '../Societe/actions';

const createBackgroundDate = (societe) => ({
    backgroundColor: societe.background_color,
    color: 'white',
    padding: '.5rem 1em',
    borderRadius: '3px',
    border: 'none',
});

class DateSelector extends React.Component {
    state = {
        selectedDate: new Date,
        modalOpen: false,
    };

    changeDate = (e) => {
        this.props.changeDate(e.target.value)
        this.props.fetchReporting({
            codvrp: this.props.selected_vrp,
            date: e.target.value,
        });
    };

    render() {
        const {selected_date } = this.props;
        return (
            <div className={"mt-3"}>
                <input type='date' className='form-control' value={selected_date} defaultValue={selected_date} onChange={(e) => this.changeDate(e)} max={(new Date).readableUS()} min='2013-01-01'/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selected_date: state.date.selected_date,
    selected_vrp: state.societes.selected_vrp,
});

export default connect(mapStateToProps, { changeDate, fetchReporting })(DateSelector);