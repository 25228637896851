import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

export function* showToastOverride(action) {
    try {
        yield put({ type: actions.HIDE_TOAST });
        // window.setTimeout(function* (){
        yield put({type: actions.SHOW_TOAST, payload: action.payload});
        // }, 500);
    } catch (error) {
        yield put({ type: actions.HIDE_TOAST });
    }
}

function* toastsSaga() {
    yield takeLatest(actions.SHOW_TOAST_OVERRIDE, showToastOverride);
}

export default toastsSaga;